import type { RepositoryName } from 'cadenza/repository/model/repository-name';
import { assert, assertNonNullable } from 'cadenza/utils/custom-error';

const REPOSITORY_NAME_URL_REGEX = '/repositories/(?<repositoryName>[^/]+)';

export function repositoryNameFromUrl (): RepositoryName {
  const match = location.pathname.match(REPOSITORY_NAME_URL_REGEX);
  assertNonNullable(match, 'No repository item id found');
  const { repositoryName } = match.groups!;
  assert(isValidRepositoryName(repositoryName), 'Invalid repository name', repositoryName);
  return repositoryName;
}

export function isValidRepositoryName (value: string): value is RepositoryName {
  return /^[\w -]{1,255}$/.test(value);
}
