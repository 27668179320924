// <GENERATED-CODE>
// Generated from Java using 'npm run generate:ts'

import type { RepositoryItemId, RepositoryItemIdTuple } from 'cadenza/repository/model/repository-item-id';
import { defineRepositoryItemId } from 'cadenza/repository/model/repository-item-id';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- Corresponding IRepositoryItemName must be in the same package.
import type { ExternalBaseLinkName } from './external-base-link-name';

/** @see net/disy/cadenza/repository/api/embedding/ExternalBaseLinkId.java */
export type ExternalBaseLinkId = RepositoryItemId<'EXTERNAL_BASE_LINK', ExternalBaseLinkName>;
/** @see net/disy/cadenza/repository/api/embedding/ExternalBaseLinkId.java */
export const ExternalBaseLinkId = defineRepositoryItemId<ExternalBaseLinkId>('EXTERNAL_BASE_LINK');
/** @see net/disy/cadenza/repository/api/embedding/ExternalBaseLinkId.java */
export type ExternalBaseLinkIdTuple = RepositoryItemIdTuple<ExternalBaseLinkName>;

// </GENERATED-CODE>
