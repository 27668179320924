import { getLogger } from 'cadenza/utils/logging';
import { isFeatureAvailable } from 'cadenza/features';
import { assertNonNullable } from 'cadenza/utils/custom-error';
import { lazy } from 'cadenza/utils/promise-utils';
import { getTargetOrigin } from 'cadenza/workbook/link/cadenza-link-utils';

const logger = getLogger('cadenza/integration/post-message');

if (isFeatureAvailable('CADENZA_JS_SANDBOX')) {
  logger.enableAll();
}

interface BaseCadenzaEvent {
  type: string;
  responsePort?: MessagePort;
}

/**
 * @see Published {@link https://jira.disy.net/browse/CADENZA-32861|CADENZA-32861} This type is also defined in `cadenza.js`.
 */
export type CadenzaEvent<T = undefined> = T extends undefined ? BaseCadenzaEvent : BaseCadenzaEvent & { detail: T };

type Subscriber<T = never> = (event: CadenzaEvent<T>) => void;

/**
 * Represents one window (target) that this cadenza instance is communicating with.
 * - If this cadenza is embedded, then use parentWindowPostMessageTarget (or subscribeToEvent, postEvent global
 *   functions). This will send messages to parent window.
 * - If this cadenza has custom applications opened in popup or another window, then create instance of
 *   PostMessageTarget for that window.
 *
 */
export class PostMessageTarget {

  readonly #subscriptions: ([string, Subscriber])[] = [];

  readonly #target;
  readonly #targetOrigin;

  /**
   *
   * @param targetOrigin - ill be used in each postEvent call for this instance. Used for security.
   * @param target - messages in postEvent will be sent to this window. If no window is provided, then
   *   this PostMessageTarget will listen for events from both parent and any other customer application window.
   */
  constructor (targetOrigin: string | (() => Promise<string>), target?: Window) {
    this.#target = target;
    this.#targetOrigin = lazy(targetOrigin);
  }

  /**
   * Subscribe to events from the target window.
   *
   * @param type - The event type
   * @param subscriber - The subscriber function
   * @return An unsubscribe function
   */
  subscribeToEvent<T = unknown> (type: string, subscriber: Subscriber<T>): () => void {
    const subscriptions = this.#subscriptions;
    if (subscriptions.length === 0) {
      window.addEventListener('message', (e) => this.__onMessage__(e));
    }
    subscriptions.push([ type, subscriber ]);
    return () => {
      subscriptions.forEach(([ subscriptionType, subscriptionSubscriber ], i) => {
        if (subscriptionType === type && subscriptionSubscriber === subscriber) {
          subscriptions.splice(i, 1);
        }
      });
      if (subscriptions.length === 0) {
        window.removeEventListener('message', (e) => this.__onMessage__(e));
      }
    };
  }

  /**
   * Post an event to the target window.
   * The event is sent using `postMessage()` to the target window.
   *
   * @param type - The event type
   * @param [detail] - The event detail
   */
  postEvent (type: string, detail?: unknown) {
    const target = this.#target;
    assertNonNullable(target, 'This PostMessageTarget has no target window defined.');
    this.#targetOrigin().then((targetOrigin) => {
      const event = { type, detail };
      logger.log(`postEvent() to ${targetOrigin}`, event);
      target.postMessage(event, targetOrigin);
    });
  }

  async __onMessage__ (event: MessageEvent<CadenzaEvent>) {
    const target = this.#target;
    if (target && target !== window.parent && event.source !== target) {
      return;
    }
    const targetOrigin = await this.#targetOrigin();
    if (targetOrigin !== '*' && event.origin !== targetOrigin) {
      return;
    }
    logger.log('Received message', event);

    const cadenzaEvent = event.data;
    cadenzaEvent.responsePort = event.ports[0];
    this.#subscriptions.forEach(([ type, subscriber ]) => {
      if (type === cadenzaEvent.type) {
        subscriber(cadenzaEvent as never);
      }
    });
  }

}

export async function handleRequest (event: CadenzaEvent, promise: Promise<unknown>) {
  const port = event.responsePort;
  assertNonNullable(port, 'The response port is required to handle a request');
  try {
    port.postMessage({
      type: `${event.type}:success`,
      detail: await promise
    });
  } catch (error) {
    port.postMessage({ type: `${event.type}:error` });
  }
}

const parentWindowPostMessageTarget = new PostMessageTarget(getParentTargetOrigin, window.parent);

/**
 * Post an event to the customer application in parent window. (This cadenza is embedded)
 *
 * The event is sent using `postMessage()` to the `parent` window. By default, the `location.origin` is used as the target origin.
 * If a `webApplication` URL parameter with the ID of an external link is present, the origin is taken form the external link.
 *
 * @param type - The event type
 * @param [detail] - The event detail
 */
export function postEvent (type: string, detail?: unknown) {
  parentWindowPostMessageTarget.postEvent(type, detail);
}

/**
 * Subscribe to events from the customer application in parent window. (This cadenza is embedded)
 *
 * @param type - The event type
 * @param subscriber - The subscriber function
 * @return An unsubscribe function
 */
export function subscribeToEvent<T = unknown> (type: string, subscriber: Subscriber<T>): () => void {
  return parentWindowPostMessageTarget.subscribeToEvent(type, subscriber);
}

// Exported for testing
export const __onMessage__ = parentWindowPostMessageTarget.__onMessage__.bind(parentWindowPostMessageTarget);

async function getParentTargetOrigin () {
  const webApplication = window.Disy.webApplication;
  if (webApplication) {
    if (webApplication === '*') {
      return '*';
    }
    try {
      const targetOrigin = await getTargetOrigin(webApplication);
      if (targetOrigin) {
        return targetOrigin;
      }
    } catch (error) {
      logger.error(`Could not resolve origin for given external link: ${webApplication}. `
        + 'Maybe the link does not exist or the user has no view privilege for it?', error);
    }
  }
  return location.origin;
}
