import './html-element-inert-polyfill.css';

// eslint-disable-next-line no-prototype-builtins
if (!HTMLElement.prototype.hasOwnProperty('inert')) {
  Object.defineProperty(HTMLElement.prototype, 'inert', {
    enumerable: true,
    get () {
      return this.hasAttribute('inert');
    },
    set (inert) {
      this.toggleAttribute('inert', inert);
    }
  });
}
