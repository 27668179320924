/**
 * @see net/disy/lib/mediatype/MediaType.java
 */

export const MIMETYPE_DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const MIMETYPE_GEOJSON = 'application/geo+json';
export const MIMETYPE_GEOPACKAGE = 'application/geopackage+vnd.sqlite3';
export const MIMETYPE_JSON = 'application/json';
export const MIMETYPE_KML = 'application/vnd.google-earth.kml+xml';
export const MIMETYPE_OCTETSTREAM = 'application/octet-stream';
export const MIMETYPE_PDF = 'application/pdf';
export const MIMETYPE_XHTML = 'application/xhtml+xml';
export const MIMETYPE_XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const MIMETYPE_ZIP = 'application/zip';
export const MIMETYPE_KMZ = 'application/vnd.google-earth.kmz';
export const MIMETYPE_GIF = 'image/gif';
export const MIMETYPE_JPEG = 'image/jpeg';
export const MIMETYPE_PNG = 'image/png';
export const MIMETYPE_SVG = 'image/svg+xml';
export const MIMETYPE_WEBP = 'image/webp';

export const MIMETYPE_CSV = 'text/csv';
export const MIMETYPE_HTML = 'text/html';
export const MIMETYPE_TEXT = 'text/plain';

export const MIMETYPE_MP4 = 'video/mp4';

export const MIMETYPE_SHAPEFILE = 'x-gis/x-shapefile';

export type ImageMediaType = typeof MIMETYPE_GIF | typeof MIMETYPE_JPEG | typeof MIMETYPE_PNG | typeof MIMETYPE_WEBP | typeof MIMETYPE_SVG;

const FILE_EXTENSION_BY_MEDIA_TYPE: Record<string, string> = {
  [MIMETYPE_DOCX]: 'docx',
  [MIMETYPE_GEOJSON]: 'json',
  [MIMETYPE_GEOPACKAGE]: 'gpkg',
  [MIMETYPE_JSON]: 'json',
  [MIMETYPE_KML]: 'kml',
  [MIMETYPE_PDF]: 'pdf',
  [MIMETYPE_XHTML]: 'xhtml',
  [MIMETYPE_XLSX]: 'xlsx',
  [MIMETYPE_ZIP]: 'zip',
  [MIMETYPE_KMZ]: 'kmz',

  [MIMETYPE_GIF]: 'gif',
  [MIMETYPE_JPEG]: 'jpg',
  [MIMETYPE_PNG]: 'png',
  [MIMETYPE_SVG]: 'svg',
  [MIMETYPE_WEBP]: 'webp',

  [MIMETYPE_CSV]: 'csv',
  [MIMETYPE_HTML]: 'html',
  [MIMETYPE_TEXT]: 'txt',

  [MIMETYPE_MP4]: 'mp4'
};

export function getDefaultFileExtension (mediaType: string) {
  const fileExtension = FILE_EXTENSION_BY_MEDIA_TYPE[mediaType];
  return fileExtension && `.${fileExtension}`;
}
