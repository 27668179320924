type Ordering = 'ascending' | 'descending';

export interface TableData<T> {
  rowCountWithoutFilter: number;
  rowCount: number;
  rows: T[];
  rowsTotal?: number;
}

export interface TableParams {
  offset?: number;
  limit?: number;
  sortOrder?: SortOrder;
  groupByColumn?: string;
  filters?: Record<string, string>;
}

export interface SortOrder {
  orderByKey: string;
  order: Ordering;
}

/*
 * Unifies the params for table endpoints (pagination, sorting, filtering).
 */
export function tableParams ({
  offset = 0,
  limit = 100,
  sortOrder,
  groupByColumn,
  filters
}: TableParams): URLSearchParams {
  const params = new URLSearchParams({ offset: String(offset), limit: String(limit) });
  if (sortOrder) {
    params.set('orderByColumn', sortOrder.orderByKey);
    params.set('orderAsc', String(sortOrder.order !== 'descending'));
  }
  if (groupByColumn) {
    params.set('groupByColumn', groupByColumn);
  }
  if (filters) {
    for (const [ filter, value ] of Object.entries(filters)) {
      if (value) {
        params.set(`filter.${filter}`, value);
      }
    }
  }
  return params;
}
